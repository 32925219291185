const links = {
    'Walt Disney': 'https://en.wikipedia.org/wiki/Walt_Disney',
    'Ub Iwerks': 'https://en.wikipedia.org/wiki/Ub_Iwerks',
    'Plane Crazy': 'https://en.wikipedia.org/wiki/Plane_Crazy',
    'Bybit': 'https://www.bybit.com/en/trade/spot/ARTY/USDT',
    'KuCoin': 'https://www.kucoin.com/zh-hant/trade/COQ-USDT',
    'twitter': 'https://twitter.com/OGMickey1928',
    'tg': 'https://t.me/+3SIzEzSwGnMyMTRl',
    'uniSwap': 'https://app.uniswap.org/tokens/arbitrum/0xbf157fb8e111c17b838db32624ff8245e430cd66'
}

export function goLink(text){
    let link = links[text]
    if(link) {
        window.open( link, '_blank')
    }
}