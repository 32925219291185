import styled from 'styled-components'
import Home from "./home";
import Logo from "../assets/logo.png";
import Buynow from "../assets/buynow.png";

const WrapperApp = styled.div`
  height: 100%;
`
const AppLogo = styled.img`
  width: 140px;
  height: 140px;
  pointer-events: none;
  position: absolute;
  top: 45px;
`
const BuyNow = styled.img`
  width: 112px;
  height: 52px;
  cursor: pointer;
`

const AppHeader = styled.div`
  background-color: #CE1428;
  width: 100%;
  height: 115px;
  box-sizing: border-box;
  opacity: .8;
  position: absolute;
  top: 0;
  left: 0;
`
const AppIn = styled.div`
  max-width: 1900px;
  height: 115px;
  margin: 0 auto;
  padding: 0 40px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

function App() {
  return (
    <WrapperApp>
      <div>
        <AppHeader>
          <AppIn>
            <AppLogo src={Logo} alt="go-mickey" />
            <div></div>
            {/* <BuyNow src={Buynow} alt="buy-now" /> */}
          </AppIn>
        </AppHeader>
        <Home></Home>
      </div>
    </WrapperApp>
  );
}

export default App;
