import styled from 'styled-components'

const TextStyle = styled.div`
	font-family: 'MARKER-FELT';
	color: ${ props => props.color };
    font-size: ${ props => props.size + 'px' };
	// text-shadow: 0 1px #000, 1px 0 #000, -1px 0 #000, 0 -1px #000;
	-webkit-text-stroke-color: ${(props) => (props.strokeC? props.strokeC : 'none')};
	-webkit-text-stroke-width: ${(props) => (props.strokeW? props.strokeW + 'px' : 'none')};
	// font-weight: 700;
`
const TextCenter = styled(TextStyle)`
	text-align: center;
	line-height: 26px;
	margin-bottom: ${ (props) => (props.bottom? props.bottom + 'px' : '0px')};
`
const TextJustify = styled(TextStyle)`
	text-align: justify;
	line-height: 26px;
`
const TextNowrap = styled(TextStyle)`
	white-space: nowrap;
	line-height: 26px;
`
const ImgStyle = styled.img`
    width: ${props => props.width + 'px'};
    height: ${props => props.height + 'px'};
`
const FlexCenter = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: ${ props => props.gap + 'px' };
`

export {
	TextStyle,
	TextCenter,
	TextJustify,
	TextNowrap,
	ImgStyle,
	FlexCenter
}