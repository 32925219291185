import React, { useEffect, useRef } from 'react';
import styled from 'styled-components'
import { TextStyle, ImgStyle, FlexCenter, TextCenter, TextJustify } from "../../style";
import TextLink from "./textLink";
import { goLink } from "../../utils/link.js";
import Union from "../../assets/union.png";
import Bybit from "../../assets/bybit.png";
import Kucoin from "../../assets/kucoin.png";
import Mickey from "../../assets/mickey.png";
import Mickey2 from "../../assets/mickey2.png";
import Mickey3 from "../../assets/mickey3.png";
import Mickey4 from "../../assets/mickey4.png";
import Scrawl from "../../assets/scrawl.png";
import familyPhoto from "../../assets/family-photo.png";
import YellowBtn from "../../assets/yellowBtn.png";
import Dg from "../../assets/dg.png";
import Tt from "../../assets/twitter.png";
import Tg from "../../assets/tg.png";


const HomeBg = styled.div`
    background: #D92E40;
`
const BgToBuyStyle = styled.div`
    // height: 1080px;
    padding: 22vh 0;
    background-image: url(${Scrawl});
    background-size: 100% 100%;    
`
const ToBuy = styled.div`
    max-width: 1900px;
    height: inherit;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    gap: 60px;
`
const Space = styled.div`
    flex: 1;
    max-width: 820px;
    margin-left: 70px;
`
const MickeyVideo = styled.div`
    flex: 1;
    margin-right: 70px;
    text-align: end;
`

const InlineText = styled(TextStyle)`
    display: inline-block;
`
const MarText = styled(TextStyle)`
    margin-bottom: 28px;
    text-align: center;
    margin-top: 30px;
`
const JumpModule = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 16px;
`

const BtnStyle = styled.div`
    width: 324px;
    height: 64px;
    background-image: url(${YellowBtn});
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    cursor: pointer;
`
const ModuleTwo = styled(FlexCenter)`
    padding: 63px 0 57px 0;
    gap: 104px;
`
const ModuleThree = styled(FlexCenter)`
    background-color: #FFFFFF;
`
const ModuleFour= styled.div`
    padding: 42px 0;
`
const ModuleFive= styled(FlexCenter)`
    padding: 88px 0;
    background-color: #FFFFFF;
    margin: 0 auto;
`
const ModuleSix= styled(FlexCenter)`
    max-width: 1320px;
    padding: 10px 40px 9px 0;
    margin: 0 auto;
    overflow: hidden;
`
const DetailsBox = styled.div`
    max-width: 629px;
`
const Background = styled.div`
    width: 780px;
    height: 666px;
    background-image: url(${familyPhoto});
    background-size: 100% 720px;
    display: flex;
    align-items: center;
`
const MaxImgStyle = styled.div`
    max-width: 1193px;
    img {
        width: 100%;
        height: 100%;
    }
`
const TextBox = styled(TextStyle)`
    width: 900px;
    min-width: 220px;
    padding: 26px 94px 46px 164px;
    border: 2px solid #fff;
    border-radius: 16px;
`
const SpinImg = styled(ImgStyle)`
    margin-right: -150px;
    z-index: 9999;
    transform: rotate(-10deg);
`
const CursorImg = styled(ImgStyle)`
    cursor: pointer;
`
const VideoBox = styled.video`
    width: 100%;
`

function Home() {
    const videoRef = useRef(null);

    useEffect(() => {
      if (videoRef?.current) {
        videoRef?.current.play()
      }
    }, []);

    return (
        <HomeBg>
            <BgToBuyStyle>
                <ToBuy>
                    <Space>
                        <TextStyle size={80} color={'#FBDF4D'} strokeC={'#000'} strokeW={1}>
                            THE MEME COIN OF THE <InlineText size={80} color={'#FFF'}>OG MICKEY</InlineText>
                        </TextStyle>
                        <MarText size={18} color={'#FFFFFF'}>Get Some $OM</MarText>
                        {/* <MarText size={18} color={'#FFFFFF'}>$OM Smart Contract Coming Soon!</MarText> */}
                        <JumpModule>
                            <BtnStyle onClick={() => goLink('uniSwap')}>
                                <TextStyle size={16} color={'#000'}>Uniswap</TextStyle>
                                <ImgStyle width={20} height={29} src={Union} alt="uniswap" />
                            </BtnStyle>
                            {/* <BtnStyle onClick={() => {
                                goLink('Bybit')
                            }}>
                                <TextStyle size={16} color={'#000'}>Bybit</TextStyle>
                                <ImgStyle width={53} height={22} src={Bybit} alt="bybit" />
                            </BtnStyle>
                            <BtnStyle onClick={() => {
                                goLink('KuCoin')
                            }}>
                                <TextStyle size={16} color={'#000'}>KUCOIN</TextStyle>
                                <ImgStyle width={20} height={27} src={Kucoin} alt="Kucoin" />
                            </BtnStyle> */}
                        </JumpModule>
                    </Space>
                    <MickeyVideo>
                        {/* <img src={Mickey} alt="mickey"/> */}
                        <VideoBox
                            ref={videoRef}
                            src={'https://static.ogmickey.io/OGMickey.mp4'}
                            autoPlay
                            loop
                            muted
                            controls
                        />
                    </MickeyVideo>
                </ToBuy>
            </BgToBuyStyle>
            <ModuleTwo>
                <ImgStyle width={242} height={322} src={Mickey2} alt="Mickey" />
                <div>
                    <DetailsBox>
                        <TextCenter size={16} color={'#FFF'} bottom={32}>
                            The OG Mickey is an American cartoon character co-created in 1928 by <TextLink text={'Walt Disney'} /> and <TextLink text={'Ub Iwerks'} />. 
                            The OG Mickey was first seen in a test screening of the cartoon short <TextLink text={'Plane Crazy'} />, on May 15, 1928.
                        </TextCenter>
                        <TextCenter size={16} color={'#FFF'} bottom={32}>
                            The OG Mickey represents endless creativity. To bring people dreamy and touching 
                            experiences, one spiritual concept remains unchanged, which is to actively explore 
                            unknown world, always pursuing brand new knowledge.
                        </TextCenter>
                        <TextCenter size={16} color={'#FFF'} bottom={32}>
                            The OG Mickey, where dreams become real.
                        </TextCenter>
                        <TextCenter size={16} color={'#FFF'}>
                            The OG Mickey stick to our original pursuit of laughing with people all over the world and 
                            enabling people to laugh forever. As long as there is imagination in this world,  every 
                            moment can be eternal..
                        </TextCenter>
                        <TextCenter size={16} color={'#FFF'} bottom={26}>
                            Embrace OG Mickey and embark on an interesting journey of Web3.0.
                        </TextCenter>
                    </DetailsBox>
                    <FlexCenter gap={12}>
                        {/* <CursorImg
                            width={28}
                            height={28}
                            src={Dg}
                            alt="icon"
                        /> */}
                        <CursorImg
                            width={24}
                            height={20}
                            src={Tt}
                            alt="icon"
                            onClick={() => goLink('twitter')}
                        />
                        <CursorImg
                            width={28}
                            height={28}
                            src={Tg}
                            alt="icon"
                            onClick={() => goLink('tg')}
                        />
                    </FlexCenter>
                    </div>
            </ModuleTwo>
            <ModuleThree>
                <Background>
                    <TextCenter size={16} color={'#000'}>
                        The first-generation Mickey(The OG Mickey),  a true Disney marvel, marks the genesis of greatness. Ninety 
                        years ago, he stepped into our lives, bringing endless wonder and joy, crafting a world filled with enchantment.The 
                        OG Mickey is more than just a cartoon mouse which embodies boundless creativity and imagination. The OG 
                        Mickey’s a timeless friend who has accompanied generations in their journey of growth.The OG Mickey have 
                        become symbols of friendship, courage, and optimism in the hearts of countless individuals worldwide.People 
                        have learned to believe in miracles, embrace daring adventures, and find solutions to any challenge, no matter 
                        how daunting. He's taught us the importance of respect and friendship, reminding us that happiness is always 
                        within our grasp, needing only a pure heart to appreciate.Today, we commemorate the first-generation Mickey, 
                        expressing our gratitude for the laughter, dreams, and enduring childlike wonder he's bestowed upon us. His story 
                        continues to be passed down within Disney's extended family, inspiring a new generation to create even more 
                        magical moments.The  First-generation Mickey, you are the soul of Disney, and your charm will never fade. Let us 
                        cherish and appreciate him, for he instills in us the belief that every day can be the start of a new miracle. 
                        Mickey, your story will continue to illuminate the skies of the future, bringing boundless joy and warmth to 
                        people all around the world.
                    </TextCenter>
                </Background>
            </ModuleThree>
            <ModuleFour>
                <TextCenter size={16} color={'#FFF'} bottom={32}>
                    Economics
                </TextCenter>
                <TextCenter size={16} color={'#FFF'} bottom={32}>
                    19.28T$OM
                </TextCenter>
                <TextCenter size={16} color={'#FFF'}>
                    10% for airdrops
                </TextCenter>
                <TextCenter size={16} color={'#FFF'} bottom={32}>
                    10% for donating to chirldren charity organization
                </TextCenter>
                <TextCenter size={16} color={'#FFF'}>
                    No reservation
                </TextCenter>
                <TextCenter size={16} color={'#FFF'} bottom={30}>
                    AI tool will be launched in the future. Users can create artwork related to OG Mickey.
                </TextCenter>
            </ModuleFour>
            <ModuleFive>
                <MaxImgStyle>
                    <img src={Mickey3} alt="Mickey"/>
                </MaxImgStyle>
            </ModuleFive>
            <ModuleSix>
                <SpinImg width={397} height={315} src={Mickey4} alt="Mickey" />
                <TextBox>
                    <TextCenter size={16} color={'#FFF'} bottom={32}>
                        DISCLAIMERS
                    </TextCenter>
                    <TextJustify size={14} color={'#FFF'} justify={'true'} >
                        THE OG MICKEY IS A MEME COIN ON ARBITRUM. THE OG MICKEY BELONGS TO PEOPLE WITH DEREAMS 
                        ALL OVER THE WORLD. THIS IS NOT INVESTMENT ADVICE. THE COIN IS FOR ENTERTAINMENT AND 
                        EDUCATIONAL PURPOSES ONLY. COPYRIGHT © 2024 ALL RIGHTS RESERVED OG MICKEY
                    </TextJustify>
                </TextBox>
            </ModuleSix>
        </HomeBg>
    )
}
export default Home