import React from 'react';
import styled from 'styled-components'
import { goLink } from "../../utils/link.js";

const TextLine = styled.span`
    text-decoration: underline;
    cursor: pointer;
`

function TextLink({text}) {
    return(
        <TextLine onClick={
            () => {
                goLink(text)
            }
        }>
            {text}
        </TextLine>
    )
}

export default TextLink